<template>
  <el-card shadow="never">
    <div slot="header" class="clearfix">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-card >
            <div slot="header" class="clearfix">
              <span style="hight:20px">客户数: {{ this.table1.total }}</span>
              <br><br>
              <span style="hight:20px; display: block;">同月比: {{ this.table1.monLastYearM }}</span>
              <span style="hight:20px; display: block;  overflow:hidden;">月环比: {{ this.table1.monLastM }}</span>
            </div>
            <div>
              <div style="hight:20px">月均客户数：
                {{ this.table1.average }}
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card >
            <div slot="header" class="clearfix">
              <span style="hight:20px">文章数: {{ this.table2.total }}</span>
              <br><br>
              <span style="hight:20px; display: block; position: relative;">同月比: {{ this.table2.monLastYearM }}</span>
              <span
                  style="hight:20px; display: block; overflow:hidden; position: relative;">月环比: {{
                  this.table2.monLastM
                }}</span>
            </div>
            <div>
              <div style="hight:20px">月均文章数：
                {{ this.table2.average }}
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="20">
      <el-col :span="15">
         <el-row>
          <span style="float: left">
            <el-button size="small" :type="activeType==='customerCount'?'info':''" @click="handleClickType('customerCount')">客户数</el-button>
            <el-button size="small" :type="activeType==='articleCount'?'info':''" @click="handleClickType('articleCount')">文章数</el-button>
            <el-button size="small" :type="activeType==='templateCount'?'info':''" @click="handleClickType('templateCount')">模板数</el-button>
          </span>
           <span style="float: right">
            <el-button size="small" :type="activeName==='month'?'info':''" @click="handleClick('month')">本月</el-button>
            <el-button size="small" :type="activeName==='quarter'?'info':''" @click="handleClick('quarter')">本季度</el-button>
            <el-button size="small" :type="activeName==='year'?'info':''" @click="handleClick('year')">本年</el-button>
         </span>
         </el-row>
         <el-divider></el-divider>
         <el-row>
           <div id="chartLineBox" style="width: 600px; height: 400px;">
           </div>
         </el-row>
      </el-col>
      <el-col :span="1"></el-col>
      <el-col :span="8">
        <div>
          <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="startAndEndTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @blur="searchHotArticle()">
          </el-date-picker>
        </div>
        <el-divider></el-divider>
        <el-table :data="hotArticles">
          <el-table-column prop="id" label="排名"></el-table-column>
          <el-table-column prop="nickName" label="用户名"></el-table-column>
          <el-table-column prop="count" label="数量"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-card>
</template>


<script>

import echarts from 'echarts'

export default {

  data() {
    return {
      startAndEndTime: "",
      table1: [],
      table2: [],
      hotArticles: [],
      activeType: this.activeType == null ? "customerCount" : this.activeType,
      activeName: this.activeName == null ? "month" : this.activeName,
      // 指定图表的配置项和数据
      option: {
        //title: {text: '客户趋势'},
        legend: {
          data: ["客户趋势"], //不显示？？？
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            //坐标轴指示器，坐标轴触发有效，
            type: "cross", //默认为line，line直线，cross十字准星，shadow阴影
            crossStyle: {
              color: "orange",
            },
          },
        },
        color: ["skyblue"],
        yAxis: {
          // type: "category",
          name: "客户数量",
        },
        xAxis: {
          type: "category",
          name: "天数",
          data: [1, 2, 3, 4, 5, 6],
        },
        series: [
          {
            type: "line",
            smooth: false, // 是否是一条平滑的曲线
            data: [3, 4, 5, 4, 6, 7],
          },
        ],
      },

    };
  },
  components: {},
  methods: {
    handleClickType(val) {
      //console.log(tab, event);
      this.activeType = val;
      this.showLineCharts();
    },
    handleClick(val) {
      this.activeName = val;
      this.showLineCharts();
    },
    searchHotArticle() {
      if (this.startAndEndTime != '' && this.startAndEndTime != null) {
        const startTIme = this.startAndEndTime[0];
        const endTIme = this.startAndEndTime[1];
        this.sortArticleList(startTIme, endTIme)
      }
    },
    showLineCharts() {
      this.$api.get(
          "/index/lineChart",
          null,
          {type1: this.activeType, type2: this.activeName},
          (successRes) => {
            const dataBase = successRes.data;
            const typeName = this.activeType;
            const name = this.activeName;
            if (dataBase.length > 0) {
              this.option.xAxis.data = dataBase.map((data) => data.x);
              this.option.series[0].data = dataBase.map((data) => data.y);
              if (typeName == "customerCount") {
                this.option.yAxis.name = "客户数量"
              } else if (typeName == "articleCount") {
                this.option.yAxis.name = "文章数量"
              } else if (typeName == "templateCount") {
                this.option.yAxis.name = "模板数量"
              }
              if (name == "month") {
                this.option.xAxis.name = "天数"
              } else if (name == "quarter") {
                this.option.xAxis.name = "月份"
              } else if (name == "year") {
                this.option.xAxis.name = "月份"
              }
              this.chartLine = echarts.init(
                  document.getElementById("chartLineBox")
              );
              // 使用刚指定的配置项和数据显示图表。
              this.chartLine.setOption(this.option);
            }
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    customerBaseData() {
      this.$api.get(
          "/index/show/customerBaseData",
          null,
          null,
          (successRes) => {
            this.table1 = successRes.data;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    articleBaseData() {
      this.$api.get(
          "/index/show/articleBaseData",
          null,
          null,
          (successRes) => {
            this.table2 = successRes.data;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    sortArticleList(startTime, endTime) {
      this.$api.get(
          "/index/sort/article",
          null,
          {startTime: startTime, endTime: endTime},
          (successRes) => {
            this.hotArticles = successRes.data;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
  },
  mounted() {
    this.customerBaseData();
    this.articleBaseData();
    this.sortArticleList(null, null);
    this.showLineCharts();
  },
};
</script>
<style scoped>
#bj {
  position: relative;
}

.card2 {
  position: absolute;
  left: 370px;
  top: 0;
}

.card1 {

}

.card3 {
  position: absolute;
  left: 740px;
  top: 0;

}

.text {
  font-size: 14px;

}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.show {
  margin-top: 20px;
  position: relative;
}

.show .right {
  position: absolute;
  right: 0;
  top: 0;
}

.box-card {
  width: 360px;
}

.time {
  position: absolute;
  right: 0;
  top: 0;
  height: 36px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>